::selection {
    color: #fff;
    background: #5a5278;
}
a,
.btn-link {
    color: #5a5278;
}
.text-green,
.text-info,
.text-success,
/* .text-danger, */
.text-primary,
.text-warning,
.mail-star.active,
.page-title {
    color: #5a5278 !important;
}
.badge-default {
    background: #3f3d56;
    color: #f1f1f1;
}
.loader {
    color: #5a5278;
}
.badge-primary {
    background-color: #5a5278;
}
.btn-primary {
    background: #5a5278;
    color: #fff;
    border-color: #5a5278;
}
.btn-primary:hover {
    background: #6c757d;
    color: #fff;
    border-color: #6c757d;
}
.btn-info {
    background-color: #e8769f;
    border-color: #e8769f;
}
.nav-tabs .nav-link.active {
    border-color: #5a5278;
    color: #5a5278;
}
.form-control:focus {
    box-shadow: 0 0 0 0.2rem rgba(90, 82, 120, 0.25);
}
.card,
.dropify-wrapper,
.btn-primary {
    border: 0;
}
.bg-primary,
.bg-info,
.bg-warning {
    background-color: #5a5278 !important;
    border: transparent;
}
.bg-success,
.bg-danger,
.badge-success,
.progress-bar,
.btn-danger,
.btn-warning {
    background-color: #e8769f;
    border: transparent;
}
.btn-outline-primary {
    color: #5a5278;
    border-color: #5a5278;
}
.btn-outline-primary:hover {
    background-color: #5a5278;
    border-color: #5a5278;
}
.custom-control-input:checked ~ .custom-control-label::before,
.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before,
.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
    border-color: #5a5278;
    background-color: #5a5278;
}
.custom-switch-input:checked ~ .custom-switch-indicator {
    background: #5a5278;
}
.selectgroup-input:checked + .selectgroup-button {
    border-color: #5a5278;
    color: #5a5278;
    background: rgba(90, 82, 120, 0.2);
}
.imagecheck-figure:before {
    color: #e8769f !important;
}
.wizard > .steps .current a,
.wizard > .steps .current a:hover,
.wizard > .steps .current a:active,
.wizard > .actions a,
.wizard > .actions a:hover {
    background: #5a5278;
}
.wizard > .steps .done a,
.wizard > .steps .done a:hover {
    background: rgba(90, 82, 120, 0.2);
    color: #5a5278;
}
.table th {
    color: #2c2945;
}
.table.table-custom td,
.table.table-custom th {
    background: #f1f1f1;
}
.right_chat li.online .status {
    background: #5a5278;
}
.bg-blue,
.bg-azure,
.bg-indigo,
.bg-purple,
.bg-red,
.bg-orange {
    background-color: #5a5278 !important;
}
.bg-pink,
.bg-yellow,
.bg-lime,
.bg-green,
.bg-teal,
.bg-cyan {
    background-color: #e8769f !important;
}
.bg-light-blue,
.bg-light-azure,
.bg-light-indigo,
.bg-light-lime,
.bg-light-green,
.bg-light-teal {
    background-color: rgba(90, 82, 120, 0.1);
    color: #5a5278;
}
.bg-light-pink,
.bg-light-red,
.bg-light-orange,
.bg-light-yellow,
.bg-light-cyan {
    background-color: rgba(232, 118, 159, 0.1);
    color: #e8769f;
}
.bg-light-purple {
    background-color: rgba(228, 189, 81, 0.1);
    color: #e4bd51;
}
.bg-light-gray {
    background-color: rgba(102, 106, 109, 0.1);
    color: #666a6d;
}

body {
    background-color: #f1f1f1;
    color: #2c2945;
}
.page-header .right .nav-pills .nav-link {
    color: #2c2945;
}
#header_top .brand-logo {
    background: #5a5278;
}
.header {
    background: #5a5278;
}
.header .nav-tabs .nav-link {
    color: #fff;
}
.header .nav-tabs .nav-link.active {
    border-color: #fff;
}
.header .dropdown-menu .dropdown-item:hover,
.header .dropdown-menu .dropdown-item.active {
    color: #5a5278;
}
.page .section-white,
.page .section-body {
    background: #f1f1f1;
}
#header_top .nav-link {
    color: #2c2945;
}
.header_top.dark {
    background-color: #39374e !important;
}
.header_top.dark .nav-link {
    color: #f1f1f1 !important;
}
.card {
    background-color: #fff;
}
.card .card-options a {
    color: #5a5278;
}
.card .card-options a.btn {
    color: #fff;
}
.card.card-fullscreen {
    background-color: #fff !important;
}
.metismenu a {
    color: #2c2945;
}
.metismenu a:hover {
    color: #5a5278;
    border-color: #5a5278;
}
.metismenu .active > a {
    color: #5a5278;
    border-color: #5a5278;
}
.metismenu .active ul .active a {
    color: #5a5278;
    background: transparent;
}
.metismenu.grid > li.active > a,
.metismenu.grid > li > a:hover {
    background: rgba(90, 82, 120, 0.1);
    color: #5a5278;
}
.inbox .detail {
    background: #fff;
}
.file_folder a {
    background: #fff;
    border-color: #f1f1f1;
}
.auth .auth_left {
    background: #fff;
}
.gender_overview {
    background: #f1f1f1;
}
.table-calendar-link {
    background: #5a5278;
}
.table-calendar-link:hover {
    background: #39374e;
}
.table-calendar-link::before {
    background: #39374e;
}
.theme_div {
    background: #fff;
}
.ribbon .ribbon-box.green {
    background: #cedd7a;
}
.ribbon .ribbon-box.green::before {
    border-color: #cedd7a;
    border-right-color: transparent;
}
.ribbon .ribbon-box.orange {
    background: #5a5278;
}
.ribbon .ribbon-box.orange::before {
    border-color: #5a5278;
    border-right-color: transparent;
}
.ribbon .ribbon-box.pink {
    background: #e8769f;
}
.ribbon .ribbon-box.pink::before {
    border-color: #e8769f;
    border-right-color: transparent;
}
.ribbon .ribbon-box.indigo {
    background: #e4bd51;
}
.ribbon .ribbon-box.indigo::before {
    border-color: #e4bd51;
    border-right-color: transparent;
}
.text-orange {
    color: #e4bd51 !important;
}
.tag-info {
    background: #5a5278;
}
.tag-success,
.tag-green {
    background: #cedd7a;
}
.tag-orange {
    background: #e8769f;
}
.tag-danger {
    background: #e8769f;
}
.bg-success {
    background: #cedd7a !important;
}
.bg-danger {
    background-color: #e8769f !important;
}
.auth .auth_left {
    background: #39374e;
}
.auth .auth_left .card {
    padding: 20px;
    right: -150px;
    z-index: 99999;
}
@media only screen and (max-width: 1024px) {
    .auth .auth_left .card {
        right: -50px;
    }
}
@media only screen and (max-width: 992px) {
    .auth .auth_left .card {
        right: auto;
    }
}
.top_dark {
    background-color: #39374e !important;
}
.sidebar_dark #left-sidebar {
    background-color: #39374e !important;
}
.sidebar_dark #header_top .nav-link {
    color: #f1f1f1 !important;
}
.sidebar_dark .sidebar .metismenu .active > a {
    color: #e4bd51;
}
.sidebar_dark .sidebar .metismenu .active ul .active a {
    color: #e4bd51;
}
.iconcolor #header_top .nav-link {
    color: #e4bd51 !important;
}
.gradient .custom-switch-input:checked ~ .custom-switch-indicator {
    background: linear-gradient(45deg, #5a5278, #e8769f) !important;
}
.gradient .metismenu.grid > li.active > a,
.gradient .metismenu.grid > li > a:hover {
    background: linear-gradient(45deg, #5a5278, #5a5278) !important;
    color: #fff;
    border: 0;
}
.gradient .btn-primary {
    background: linear-gradient(45deg, #5a5278, #e8769f) !important;
    border: 0;
}
.gradient .btn-dark {
    background: linear-gradient(45deg, #808488, #333537) !important;
}
.gradient .bg-success,
.gradient .bg-danger,
.gradient .badge-success,
.gradient .progress-bar,
.gradient .btn-danger {
    background: linear-gradient(45deg, #5a5278, #e8769f) !important;
}
.metismenu.grid > li > a {
    border-color: #5a5278;
}
