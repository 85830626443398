.fc-event-container .fc-event,
.fc-event-container .fc-event-dot {
    background-color: #39374e !important;
    color: #babdbf !important;
    border-color: #39374e !important;
}

.nav-link-gdc-selected {
    font-weight: bold !important;
}

div.font-montserrat {
    font-family: "Montserrat", sans-serif;
}
div.font-roboto {
    font-family: "Robot", sans-serif;
}
div.font-opensans {
    font-family: "Open Sans", sans-serif;
    font-size: 14px;
}
/* div.rtl{ */
div.rtl {
    direction: rtl !important;
    text-align: right;
}
.sticky-top {
    width: calc(100% - 280px);
    position: fixed;
}
.marginTop {
    margin-top: 7.2% !important;
}

:root {
    /* // ---------- Date Picker
     --weekend: rgba(0, 0, 0, 0);
     --date-light: #fff;
     --date-primary: #5644c1;
     --date-success: #37d37d;
     --date-primaryLight: #eceaf5;
     --date-primaryTitle: #dbd8f0; */
    --date-hover: #39374e;
    --date-highlight: #dc3545;
    --date-disabled: #c2c1cc;
    --date-width: 300px;
    --date-height: 280px;
}

.dateContainer {
    background-color: #fff !important;
}
.dateContainer .days div.selected_day {
    background-color: #39374e !important;
    color: #fff !important;
}
.dateContainer .header .next, 
.dateContainer .header .prev{
    box-shadow: none !important;
}
.dateContainer .header {
    display: block;
}

.calendar-box {
    position: relative;
}
.calendar-box.date-bottom .dateContainer {
    bottom: 0px;
}
.calendar-box.date-top .dateContainer {
    top: 0px;
}
.calendar-box .icon {
    right: 20px;
    top: 45%;
}

.text-light {
    color:#babdbf;
}