@import './assets/plugins/bootstrap/css/bootstrap.min.css';

@import './assets/css/main.css';

@import './assets/css/theme2.css';

@import './assets/css/custom.css';

@import './assets/plugins/charts-c3/c3.min.css';

@import './assets/plugins/summernote/dist/summernote.css';

@import './assets/plugins/jvectormap/jvectormap-2.0.3.css';

@import './assets/plugins/bootstrap-multiselect/bootstrap-multiselect.css';

@import './assets/plugins/bootstrap-datepicker/css/bootstrap-datepicker3.min.css';

@import './assets/plugins/fullcalendar/fullcalendar.min.css';

@import './assets/plugins/jquery-steps/jquery.steps.css';

@import './assets/tw-main.css';

@import '../node_modules/antd/dist/antd.css';



input[type="text"]:disabled{background-color:rgb(247, 246, 246);}
select[disabled] { background-color: #f7f6f6; }


/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.modal-elem {
    width: 100vw;
    height: 100vh;
    position: fixed;
    z-index: 99999;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
  }
  
  /* // Bootstrap Modal */
  .modal {
    display: block;
  }


  .steps-content {
    min-height: 200px;
    margin-top: 16px;
    padding-top: 80px;
    text-align: center;
    background-color: #fafafa;
    border: 1px dashed #e9e9e9;
    border-radius: 2px;
  }
  
  .steps-action {
    margin-top: 24px;
  }


  /* Chrome, Safari, Edge, Opera */
.hide-side-arrow input::-webkit-outer-spin-button,
.hide-side-arrow input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.hide-side-arrow input[type=number] {
  -moz-appearance: textfield;
}

::-webkit-scrollbar {
  width: 0px !important;
  background: transparent !important; /* make scrollbar transparent */
}

/* width */
.ant-table ::-webkit-scrollbar {
  height: 7px;
}
/* Track */
.ant-table ::-webkit-scrollbar-track {
 background: #F9F9F9;
}

/* Handle */
.ant-table ::-webkit-scrollbar-thumb {
  background: #5a5278;
  border-radius: 5px;
}


.ant-table:hover ::-webkit-scrollbar-thumb {
  background: #5a5278;
  border-radius: 5px;
  
}

.ant-table-ping-right:not(.ant-table-has-fix-right) > .ant-table-container::after{
  box-shadow: none;
}


.ant-table-tbody > tr > td {
  position: relative;
}

.avatar-upload{
  width: 115px;
  height: 115px;
  border: 1px dashed #d9d9d9;
  padding:5px;
  border-radius: 50%;
  transition: 0.2s;
  
}
.avatar-upload:hover{
  border-color: #adadad;
  background-color: #d9d9d929;
}

.avatar-upload .ant-upload.ant-upload-select-picture-card{
  border: none;
  border-radius: 50%;
  overflow: hidden;
}
.ant-picker-calendar-full .ant-picker-panel .ant-picker-body th{
  padding-left: 0.8rem;
}
.ant-picker-calendar-date-value, .ant-picker-calendar-full .ant-picker-panel .ant-picker-body th{
  text-align: left;
  /* font-weight: 500; */
}