*, ::before, ::after {

    --tw-border-spacing-x: 0;

    --tw-border-spacing-y: 0;

    --tw-translate-x: 0;

    --tw-translate-y: 0;

    --tw-rotate: 0;

    --tw-skew-x: 0;

    --tw-skew-y: 0;

    --tw-scale-x: 1;

    --tw-scale-y: 1;

    --tw-pan-x:  ;

    --tw-pan-y:  ;

    --tw-pinch-zoom:  ;

    --tw-scroll-snap-strictness: proximity;

    --tw-ordinal:  ;

    --tw-slashed-zero:  ;

    --tw-numeric-figure:  ;

    --tw-numeric-spacing:  ;

    --tw-numeric-fraction:  ;

    --tw-ring-inset:  ;

    --tw-ring-offset-width: 0px;

    --tw-ring-offset-color: #fff;

    --tw-ring-color: rgb(59 130 246 / 0.5);

    --tw-ring-offset-shadow: 0 0 #0000;

    --tw-ring-shadow: 0 0 #0000;

    --tw-shadow: 0 0 #0000;

    --tw-shadow-colored: 0 0 #0000;

    --tw-blur:  ;

    --tw-brightness:  ;

    --tw-contrast:  ;

    --tw-grayscale:  ;

    --tw-hue-rotate:  ;

    --tw-invert:  ;

    --tw-saturate:  ;

    --tw-sepia:  ;

    --tw-drop-shadow:  ;

    --tw-backdrop-blur:  ;

    --tw-backdrop-brightness:  ;

    --tw-backdrop-contrast:  ;

    --tw-backdrop-grayscale:  ;

    --tw-backdrop-hue-rotate:  ;

    --tw-backdrop-invert:  ;

    --tw-backdrop-opacity:  ;

    --tw-backdrop-saturate:  ;

    --tw-backdrop-sepia:  
}

::backdrop {

    --tw-border-spacing-x: 0;

    --tw-border-spacing-y: 0;

    --tw-translate-x: 0;

    --tw-translate-y: 0;

    --tw-rotate: 0;

    --tw-skew-x: 0;

    --tw-skew-y: 0;

    --tw-scale-x: 1;

    --tw-scale-y: 1;

    --tw-pan-x:  ;

    --tw-pan-y:  ;

    --tw-pinch-zoom:  ;

    --tw-scroll-snap-strictness: proximity;

    --tw-ordinal:  ;

    --tw-slashed-zero:  ;

    --tw-numeric-figure:  ;

    --tw-numeric-spacing:  ;

    --tw-numeric-fraction:  ;

    --tw-ring-inset:  ;

    --tw-ring-offset-width: 0px;

    --tw-ring-offset-color: #fff;

    --tw-ring-color: rgb(59 130 246 / 0.5);

    --tw-ring-offset-shadow: 0 0 #0000;

    --tw-ring-shadow: 0 0 #0000;

    --tw-shadow: 0 0 #0000;

    --tw-shadow-colored: 0 0 #0000;

    --tw-blur:  ;

    --tw-brightness:  ;

    --tw-contrast:  ;

    --tw-grayscale:  ;

    --tw-hue-rotate:  ;

    --tw-invert:  ;

    --tw-saturate:  ;

    --tw-sepia:  ;

    --tw-drop-shadow:  ;

    --tw-backdrop-blur:  ;

    --tw-backdrop-brightness:  ;

    --tw-backdrop-contrast:  ;

    --tw-backdrop-grayscale:  ;

    --tw-backdrop-hue-rotate:  ;

    --tw-backdrop-invert:  ;

    --tw-backdrop-opacity:  ;

    --tw-backdrop-saturate:  ;

    --tw-backdrop-sepia:  
}

.tw-pointer-events-none {

    pointer-events: none !important
}

.tw-visible {

    visibility: visible !important
}

.tw-invisible {

    visibility: hidden !important
}

.tw-absolute {

    position: absolute !important
}

.tw-relative {

    position: relative !important
}

.tw-right-1 {

    right: 0.25rem !important
}

.tw-top-\[0\.4rem\] {

    top: 0.4rem !important
}

.tw-top-\[2rem\] {

    top: 2rem !important
}

.tw-bottom-\[1\.1rem\] {

    bottom: 1.1rem !important
}

.tw-right-0 {

    right: 0px !important
}

.-tw-bottom-2 {

    bottom: -0.5rem !important
}

.tw-left-2 {

    left: 0.5rem !important
}

.tw-right-2 {

    right: 0.5rem !important
}

.-tw-top-\[3\.5rem\] {

    top: -3.5rem !important
}

.tw-left-\[2\.2rem\] {

    left: 2.2rem !important
}

.-tw-top-\[3rem\] {

    top: -3rem !important
}

.tw-left-\[10rem\] {

    left: 10rem !important
}

.tw-z-50 {

    z-index: 50 !important
}

.tw-col-span-2 {

    grid-column: span 2 / span 2 !important
}

.tw-m-0 {

    margin: 0px !important
}

.tw-my-2 {

    margin-top: 0.5rem !important;

    margin-bottom: 0.5rem !important
}

.tw-mx-auto {

    margin-left: auto !important;

    margin-right: auto !important
}

.-tw-mx-\[2rem\] {

    margin-left: -2rem !important;

    margin-right: -2rem !important
}

.-tw-mx-2 {

    margin-left: -0.5rem !important;

    margin-right: -0.5rem !important
}

.-tw-mx-4 {

    margin-left: -1rem !important;

    margin-right: -1rem !important
}

.tw-my-4 {

    margin-top: 1rem !important;

    margin-bottom: 1rem !important
}

.-tw-mx-5 {

    margin-left: -1.25rem !important;

    margin-right: -1.25rem !important
}

.tw-mb-2 {

    margin-bottom: 0.5rem !important
}

.tw-mb-1 {

    margin-bottom: 0.25rem !important
}

.tw-mt-1 {

    margin-top: 0.25rem !important
}

.tw-mb-4 {

    margin-bottom: 1rem !important
}

.tw-mb-0 {

    margin-bottom: 0px !important
}

.-tw-mt-1 {

    margin-top: -0.25rem !important
}

.tw-mt-2 {

    margin-top: 0.5rem !important
}

.tw-mr-4 {

    margin-right: 1rem !important
}

.tw-mt-4 {

    margin-top: 1rem !important
}

.tw-mb-8 {

    margin-bottom: 2rem !important
}

.tw-mt-8 {

    margin-top: 2rem !important
}

.tw-ml-2 {

    margin-left: 0.5rem !important
}

.tw-mb-3 {

    margin-bottom: 0.75rem !important
}

.-tw-ml-2 {

    margin-left: -0.5rem !important
}

.tw-block {

    display: block !important
}

.tw-flex {

    display: flex !important
}

.tw-grid {

    display: grid !important
}

.tw-h-\[2rem\] {

    height: 2rem !important
}

.tw-h-\[94\%\] {

    height: 94% !important
}

.tw-h-3 {

    height: 0.75rem !important
}

.tw-h-full {

    height: 100% !important
}

.tw-h-\[4rem\] {

    height: 4rem !important
}

.tw-h-\[1\.8rem\] {

    height: 1.8rem !important
}

.tw-h-\[1\.9rem\] {

    height: 1.9rem !important
}

.tw-h-\[450px\] {

    height: 450px !important
}

.tw-h-4 {

    height: 1rem !important
}

.tw-max-h-\[500px\] {

    max-height: 500px !important
}

.tw-min-h-\[calc\(100vh-9rem\)\] {

    min-height: calc(100vh - 9rem) !important
}

.tw-min-h-\[90px\] {

    min-height: 90px !important
}

.tw-w-full {

    width: 100% !important
}

.tw-w-3 {

    width: 0.75rem !important
}

.tw-w-\[2px\] {

    width: 2px !important
}

.tw-w-\[4rem\] {

    width: 4rem !important
}

.tw-w-\[5rem\] {

    width: 5rem !important
}

.tw-w-\[3rem\] {

    width: 3rem !important
}

.tw-w-\[2rem\] {

    width: 2rem !important
}

.tw-w-\[14rem\] {

    width: 14rem !important
}

.tw-w-\[7rem\] {

    width: 7rem !important
}

.tw-w-\[25rem\] {

    width: 25rem !important
}

.tw-w-\[95\%\] {

    width: 95% !important
}

.tw-w-\[1rem\] {

    width: 1rem !important
}

.tw-w-\[50\%\] {

    width: 50% !important
}

.tw-w-\[12rem\] {

    width: 12rem !important
}

.tw-w-\[10rem\] {

    width: 10rem !important
}

.tw-w-\[8rem\] {

    width: 8rem !important
}

.tw-w-4 {

    width: 1rem !important
}

.tw-w-\[300px\] {

    width: 300px !important
}

.tw-w-\[9rem\] {

    width: 9rem !important
}

.tw-max-w-\[4rem\] {

    max-width: 4rem !important
}

.tw-flex-1 {

    flex: 1 1 0% !important
}

.tw-flex-\[2\] {

    flex: 2 !important
}

.tw-flex-\[1\] {

    flex: 1 !important
}

.tw-flex-\[8\] {

    flex: 8 !important
}

.tw-flex-none {

    flex: none !important
}

.tw-cursor-pointer {

    cursor: pointer !important
}

.tw-appearance-none {

    appearance: none !important
}

.tw-grid-cols-2 {

    grid-template-columns: repeat(2, minmax(0, 1fr)) !important
}

.tw-flex-col {

    flex-direction: column !important
}

.tw-flex-nowrap {

    flex-wrap: nowrap !important
}

.tw-items-center {

    align-items: center !important
}

.tw-items-stretch {

    align-items: stretch !important
}

.tw-justify-start {

    justify-content: flex-start !important
}

.tw-justify-end {

    justify-content: flex-end !important
}

.tw-justify-center {

    justify-content: center !important
}

.tw-justify-between {

    justify-content: space-between !important
}

.tw-gap-4 {

    gap: 1rem !important
}

.tw-gap-2 {

    gap: 0.5rem !important
}

.tw-gap-8 {

    gap: 2rem !important
}

.tw-gap-1 {

    gap: 0.25rem !important
}

.tw-overflow-y-scroll {

    overflow-y: scroll !important
}

.tw-rounded-\[5px\] {

    border-radius: 5px !important
}

.tw-rounded-full {

    border-radius: 9999px !important
}

.tw-rounded {

    border-radius: 0.25rem !important
}

.tw-rounded-md {

    border-radius: 0.375rem !important
}

.tw-rounded-none {

    border-radius: 0px !important
}

.tw-rounded-\[4px\] {

    border-radius: 4px !important
}

.tw-rounded-l-\[5px\] {

    border-top-left-radius: 5px !important;

    border-bottom-left-radius: 5px !important
}

.tw-rounded-r-\[0px\] {

    border-top-right-radius: 0px !important;

    border-bottom-right-radius: 0px !important
}

.tw-rounded-r-md {

    border-top-right-radius: 0.375rem !important;

    border-bottom-right-radius: 0.375rem !important
}

.tw-rounded-r-none {

    border-top-right-radius: 0px !important;

    border-bottom-right-radius: 0px !important
}

.tw-rounded-l-\[0\.15rem\] {

    border-top-left-radius: 0.15rem !important;

    border-bottom-left-radius: 0.15rem !important
}

.tw-rounded-l-none {

    border-top-left-radius: 0px !important;

    border-bottom-left-radius: 0px !important
}

.tw-rounded-r-\[0\.15rem\] {

    border-top-right-radius: 0.15rem !important;

    border-bottom-right-radius: 0.15rem !important
}

.tw-rounded-tl-md {

    border-top-left-radius: 0.375rem !important
}

.tw-rounded-bl-md {

    border-bottom-left-radius: 0.375rem !important
}

.tw-rounded-tr-md {

    border-top-right-radius: 0.375rem !important
}

.tw-rounded-br-md {

    border-bottom-right-radius: 0.375rem !important
}

.tw-border {

    border-width: 1px !important
}

.tw-border-2 {

    border-width: 2px !important
}

.tw-border-r-0 {

    border-right-width: 0px !important
}

.tw-border-l-0 {

    border-left-width: 0px !important
}

.tw-border-none {

    border-style: none !important
}

.tw-border-gray-300 {

    --tw-border-opacity: 1 !important;

    border-color: rgb(209 213 219 / var(--tw-border-opacity)) !important
}

.tw-border-gray-100 {

    --tw-border-opacity: 1 !important;

    border-color: rgb(243 244 246 / var(--tw-border-opacity)) !important
}

.tw-border-red-300 {

    --tw-border-opacity: 1 !important;

    border-color: rgb(252 165 165 / var(--tw-border-opacity)) !important
}

.tw-bg-gray-50 {

    --tw-bg-opacity: 1 !important;

    background-color: rgb(249 250 251 / var(--tw-bg-opacity)) !important
}

.tw-bg-black {

    --tw-bg-opacity: 1 !important;

    background-color: rgb(0 0 0 / var(--tw-bg-opacity)) !important
}

.tw-bg-orange-200 {

    --tw-bg-opacity: 1 !important;

    background-color: rgb(254 215 170 / var(--tw-bg-opacity)) !important
}

.tw-bg-gray-200 {

    --tw-bg-opacity: 1 !important;

    background-color: rgb(229 231 235 / var(--tw-bg-opacity)) !important
}

.tw-bg-blue-200 {

    --tw-bg-opacity: 1 !important;

    background-color: rgb(191 219 254 / var(--tw-bg-opacity)) !important
}

.tw-bg-red-50 {

    --tw-bg-opacity: 1 !important;

    background-color: rgb(254 242 242 / var(--tw-bg-opacity)) !important
}

.tw-bg-blue-100 {

    --tw-bg-opacity: 1 !important;

    background-color: rgb(219 234 254 / var(--tw-bg-opacity)) !important
}

.tw-bg-gray-300 {

    --tw-bg-opacity: 1 !important;

    background-color: rgb(209 213 219 / var(--tw-bg-opacity)) !important
}

.tw-bg-white {

    --tw-bg-opacity: 1 !important;

    background-color: rgb(255 255 255 / var(--tw-bg-opacity)) !important
}

.tw-bg-gray-100 {

    --tw-bg-opacity: 1 !important;

    background-color: rgb(243 244 246 / var(--tw-bg-opacity)) !important
}

.tw-bg-slate-800 {

    --tw-bg-opacity: 1 !important;

    background-color: rgb(30 41 59 / var(--tw-bg-opacity)) !important
}

.tw-bg-slate-50 {

    --tw-bg-opacity: 1 !important;

    background-color: rgb(248 250 252 / var(--tw-bg-opacity)) !important
}

.tw-bg-\[\#272c31\] {

    --tw-bg-opacity: 1 !important;

    background-color: rgb(39 44 49 / var(--tw-bg-opacity)) !important
}

.tw-bg-slate-100 {

    --tw-bg-opacity: 1 !important;

    background-color: rgb(241 245 249 / var(--tw-bg-opacity)) !important
}

.tw-bg-green-200 {

    --tw-bg-opacity: 1 !important;

    background-color: rgb(187 247 208 / var(--tw-bg-opacity)) !important
}

.tw-bg-green-500 {

    --tw-bg-opacity: 1 !important;

    background-color: rgb(34 197 94 / var(--tw-bg-opacity)) !important
}

.tw-bg-green-600 {

    --tw-bg-opacity: 1 !important;

    background-color: rgb(22 163 74 / var(--tw-bg-opacity)) !important
}

.tw-fill-sky-500 {

    fill: #0ea5e9 !important
}

.tw-p-\[5px\] {

    padding: 5px !important
}

.tw-p-4 {

    padding: 1rem !important
}

.tw-p-2 {

    padding: 0.5rem !important
}

.tw-p-8 {

    padding: 2rem !important
}

.tw-py-2 {

    padding-top: 0.5rem !important;

    padding-bottom: 0.5rem !important
}

.tw-px-3 {

    padding-left: 0.75rem !important;

    padding-right: 0.75rem !important
}

.tw-px-8 {

    padding-left: 2rem !important;

    padding-right: 2rem !important
}

.tw-px-2 {

    padding-left: 0.5rem !important;

    padding-right: 0.5rem !important
}

.tw-py-\[0\.5rem\] {

    padding-top: 0.5rem !important;

    padding-bottom: 0.5rem !important
}

.tw-px-\[0\.8rem\] {

    padding-left: 0.8rem !important;

    padding-right: 0.8rem !important
}

.tw-px-4 {

    padding-left: 1rem !important;

    padding-right: 1rem !important
}

.tw-px-\[2rem\] {

    padding-left: 2rem !important;

    padding-right: 2rem !important
}

.tw-py-1 {

    padding-top: 0.25rem !important;

    padding-bottom: 0.25rem !important
}

.tw-py-4 {

    padding-top: 1rem !important;

    padding-bottom: 1rem !important
}

.tw-px-5 {

    padding-left: 1.25rem !important;

    padding-right: 1.25rem !important
}

.tw-py-0 {

    padding-top: 0px !important;

    padding-bottom: 0px !important
}

.tw-py-\[0\.1rem\] {

    padding-top: 0.1rem !important;

    padding-bottom: 0.1rem !important
}

.tw-px-\[0\.4rem\] {

    padding-left: 0.4rem !important;

    padding-right: 0.4rem !important
}

.tw-pl-4 {

    padding-left: 1rem !important
}

.tw-pb-\[4px\] {

    padding-bottom: 4px !important
}

.tw-pb-4 {

    padding-bottom: 1rem !important
}

.tw-pl-2 {

    padding-left: 0.5rem !important
}

.tw-text-left {

    text-align: left !important
}

.tw-text-center {

    text-align: center !important
}

.tw-text-right {

    text-align: right !important
}

.tw-text-\[10px\] {

    font-size: 10px !important
}

.tw-text-\[1rem\] {

    font-size: 1rem !important
}

.tw-text-\[1\.1rem\] {

    font-size: 1.1rem !important
}

.tw-text-\[0\.7rem\] {

    font-size: 0.7rem !important
}

.tw-text-\[1\.5rem\] {

    font-size: 1.5rem !important
}

.tw-text-\[0\.8rem\] {

    font-size: 0.8rem !important
}

.tw-text-\[2\.5rem\] {

    font-size: 2.5rem !important
}

.tw-text-\[12px\] {

    font-size: 12px !important
}

.tw-text-\[0\.5rem\] {

    font-size: 0.5rem !important
}

.tw-text-\[0\.6rem\] {

    font-size: 0.6rem !important
}

.tw-text-\[0\.75rem\] {

    font-size: 0.75rem !important
}

.tw-font-bold {

    font-weight: 700 !important
}

.tw-font-semibold {

    font-weight: 600 !important
}

.tw-font-normal {

    font-weight: 400 !important
}

.tw-uppercase {

    text-transform: uppercase !important
}

.tw-lowercase {

    text-transform: lowercase !important
}

.tw-capitalize {

    text-transform: capitalize !important
}

.tw-italic {

    font-style: italic !important
}

.tw-text-gray-400 {

    --tw-text-opacity: 1 !important;

    color: rgb(156 163 175 / var(--tw-text-opacity)) !important
}

.tw-text-rose-600 {

    --tw-text-opacity: 1 !important;

    color: rgb(225 29 72 / var(--tw-text-opacity)) !important
}

.tw-text-green-600 {

    --tw-text-opacity: 1 !important;

    color: rgb(22 163 74 / var(--tw-text-opacity)) !important
}

.tw-text-\[\#1890ff\] {

    --tw-text-opacity: 1 !important;

    color: rgb(24 144 255 / var(--tw-text-opacity)) !important
}

.tw-text-orange-800 {

    --tw-text-opacity: 1 !important;

    color: rgb(154 52 18 / var(--tw-text-opacity)) !important
}

.tw-text-blue-800 {

    --tw-text-opacity: 1 !important;

    color: rgb(30 64 175 / var(--tw-text-opacity)) !important
}

.tw-text-green-700 {

    --tw-text-opacity: 1 !important;

    color: rgb(21 128 61 / var(--tw-text-opacity)) !important
}

.tw-text-red-400 {

    --tw-text-opacity: 1 !important;

    color: rgb(248 113 113 / var(--tw-text-opacity)) !important
}

.tw-text-emerald-500 {

    --tw-text-opacity: 1 !important;

    color: rgb(16 185 129 / var(--tw-text-opacity)) !important
}

.tw-text-cyan-500 {

    --tw-text-opacity: 1 !important;

    color: rgb(6 182 212 / var(--tw-text-opacity)) !important
}

.tw-text-fuchsia-700 {

    --tw-text-opacity: 1 !important;

    color: rgb(162 28 175 / var(--tw-text-opacity)) !important
}

.tw-text-green-500 {

    --tw-text-opacity: 1 !important;

    color: rgb(34 197 94 / var(--tw-text-opacity)) !important
}

.tw-text-red-500 {

    --tw-text-opacity: 1 !important;

    color: rgb(239 68 68 / var(--tw-text-opacity)) !important
}

.tw-text-slate-500\/50 {

    color: rgb(100 116 139 / 0.5) !important
}

.tw-text-\[\#7d8490\] {

    --tw-text-opacity: 1 !important;

    color: rgb(125 132 144 / var(--tw-text-opacity)) !important
}

.tw-text-green-900 {

    --tw-text-opacity: 1 !important;

    color: rgb(20 83 45 / var(--tw-text-opacity)) !important
}

.tw-text-red-900 {

    --tw-text-opacity: 1 !important;

    color: rgb(127 29 29 / var(--tw-text-opacity)) !important
}

.tw-text-green-800 {

    --tw-text-opacity: 1 !important;

    color: rgb(22 101 52 / var(--tw-text-opacity)) !important
}

.tw-text-gray-500 {

    --tw-text-opacity: 1 !important;

    color: rgb(107 114 128 / var(--tw-text-opacity)) !important
}

.tw-text-red-600 {

    --tw-text-opacity: 1 !important;

    color: rgb(220 38 38 / var(--tw-text-opacity)) !important
}

.tw-text-white {

    --tw-text-opacity: 1 !important;

    color: rgb(255 255 255 / var(--tw-text-opacity)) !important
}

.tw-underline {

    text-decoration-line: underline !important
}

.tw-opacity-75 {

    opacity: 0.75 !important
}

.tw-opacity-30 {

    opacity: 0.3 !important
}

.tw-opacity-50 {

    opacity: 0.5 !important
}

.tw-shadow {

    --tw-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1) !important;

    --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color) !important;

    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important
}

.tw-shadow-sm {

    --tw-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05) !important;

    --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color) !important;

    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important
}

.tw-shadow-md {

    --tw-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1) !important;

    --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color) !important;

    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important
}

.tw-outline-none {

    outline: 2px solid transparent !important;

    outline-offset: 2px !important
}

.tw-transition {

    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter !important;

    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter !important;

    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-backdrop-filter !important;

    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !important;

    transition-duration: 150ms !important
}

.tw-transition-all {

    transition-property: all !important;

    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !important;

    transition-duration: 150ms !important
}

.tw-transition-opacity {

    transition-property: opacity !important;

    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !important;

    transition-duration: 150ms !important
}

.tw-duration-300 {

    transition-duration: 300ms !important
}

.tw-ease-in-out {

    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !important
}

.placeholder\:tw-capitalize::placeholder {

    text-transform: capitalize !important
}

.hover\:tw-bg-orange-300:hover {

    --tw-bg-opacity: 1 !important;

    background-color: rgb(253 186 116 / var(--tw-bg-opacity)) !important
}

.hover\:tw-bg-blue-300:hover {

    --tw-bg-opacity: 1 !important;

    background-color: rgb(147 197 253 / var(--tw-bg-opacity)) !important
}

.hover\:tw-bg-green-300:hover {

    --tw-bg-opacity: 1 !important;

    background-color: rgb(134 239 172 / var(--tw-bg-opacity)) !important
}

.hover\:tw-bg-green-600:hover {

    --tw-bg-opacity: 1 !important;

    background-color: rgb(22 163 74 / var(--tw-bg-opacity)) !important
}

.hover\:tw-text-red-600:hover {

    --tw-text-opacity: 1 !important;

    color: rgb(220 38 38 / var(--tw-text-opacity)) !important
}

.hover\:tw-text-blue-500:hover {

    --tw-text-opacity: 1 !important;

    color: rgb(59 130 246 / var(--tw-text-opacity)) !important
}

.hover\:tw-shadow-lg:hover {

    --tw-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1) !important;

    --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color) !important;

    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important
}

.focus\:tw-border-blue-300:focus {

    --tw-border-opacity: 1 !important;

    border-color: rgb(147 197 253 / var(--tw-border-opacity)) !important
}

.focus\:tw-outline-none:focus {

    outline: 2px solid transparent !important;

    outline-offset: 2px !important
}

.focus\:tw-ring-2:focus {

    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color) !important;

    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color) !important;

    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000) !important
}

.disabled\:tw-bg-\[\#f7f6f6\]:disabled {

    --tw-bg-opacity: 1 !important;

    background-color: rgb(247 246 246 / var(--tw-bg-opacity)) !important
}

@media (max-width: 768px) {

    .max-\[768px\]\:tw-hidden {

        display: none !important
    }
}

@media (min-width: 640px) {

    .sm\:tw-absolute {

        position: absolute !important
    }

    .sm\:tw-grid-cols-2 {

        grid-template-columns: repeat(2, minmax(0, 1fr)) !important
    }

    .sm\:tw-flex-row {

        flex-direction: row !important
    }

    .sm\:tw-flex-col {

        flex-direction: column !important
    }

    .sm\:tw-items-start {

        align-items: flex-start !important
    }

    .sm\:tw-justify-start {

        justify-content: flex-start !important
    }
}

@media (min-width: 768px) {

    .md\:-tw-top-\[3\.2rem\] {

        top: -3.2rem !important
    }

    .md\:tw-flex-col {

        flex-direction: column !important
    }

    .md\:tw-pr-16 {

        padding-right: 4rem !important
    }

    .md\:tw-pl-16 {

        padding-left: 4rem !important
    }
}

@media (min-width: 1024px) {

    .lg\:tw-w-\[50\%\] {

        width: 50% !important
    }
}

@media (min-width: 1200px) {

    .min-\[1200px\]\:tw-hidden {

        display: none !important
    }
}

.\[\&div_img\]\:tw-w-\[200px\]div img {

    width: 200px !important
}

.\[\&\>div_p\]\:tw-mb-1>div p {

    margin-bottom: 0.25rem !important
}

.\[\&\>div_p\]\:tw-font-semibold>div p {

    font-weight: 600 !important
}

.\[\&\>p\]\:tw-mb-0>p {

    margin-bottom: 0px !important
}

.\[\&\>p\]\:tw-pl-3>p {

    padding-left: 0.75rem !important
}

.\[\&_div_p\]\:tw-mb-1 div p {

    margin-bottom: 0.25rem !important
}

.\[\&\>div_label\]\:tw-text-\[0\.7rem\]>div label {

    font-size: 0.7rem !important
}

